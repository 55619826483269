var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RcMentions from 'rc-mentions';
import Spin from '../spin';
import { ConfigContext } from '../config-provider';
import { composeRef } from '../_util/ref';
export const { Option } = RcMentions;
function loadingFilterOption() {
    return true;
}
const InternalMentions = (_a, ref) => {
    var { prefixCls: customizePrefixCls, className, disabled, loading, filterOption, children, notFoundContent } = _a, restProps = __rest(_a, ["prefixCls", "className", "disabled", "loading", "filterOption", "children", "notFoundContent"]);
    const [focused, setFocused] = React.useState(false);
    const innerRef = React.useRef();
    const mergedRef = composeRef(ref, innerRef);
    const { getPrefixCls, renderEmpty, direction } = React.useContext(ConfigContext);
    const onFocus = (...args) => {
        if (restProps.onFocus) {
            restProps.onFocus(...args);
        }
        setFocused(true);
    };
    const onBlur = (...args) => {
        if (restProps.onBlur) {
            restProps.onBlur(...args);
        }
        setFocused(false);
    };
    const getNotFoundContent = () => {
        if (notFoundContent !== undefined) {
            return notFoundContent;
        }
        return renderEmpty('Select');
    };
    const getOptions = () => {
        if (loading) {
            return (<Option value="ANTD_SEARCHING" disabled>
          <Spin size="small"/>
        </Option>);
        }
        return children;
    };
    const getFilterOption = () => {
        if (loading) {
            return loadingFilterOption;
        }
        return filterOption;
    };
    const prefixCls = getPrefixCls('mentions', customizePrefixCls);
    const mergedClassName = classNames({
        [`${prefixCls}-disabled`]: disabled,
        [`${prefixCls}-focused`]: focused,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (<RcMentions prefixCls={prefixCls} notFoundContent={getNotFoundContent()} className={mergedClassName} disabled={disabled} direction={direction} {...restProps} filterOption={getFilterOption()} onFocus={onFocus} onBlur={onBlur} ref={mergedRef}>
      {getOptions()}
    </RcMentions>);
};
const Mentions = React.forwardRef(InternalMentions);
Mentions.displayName = 'Mentions';
Mentions.Option = Option;
Mentions.getMentions = (value = '', config) => {
    const { prefix = '@', split = ' ' } = config || {};
    const prefixList = Array.isArray(prefix) ? prefix : [prefix];
    return value
        .split(split)
        .map((str = '') => {
        let hitPrefix = null;
        prefixList.some(prefixStr => {
            const startStr = str.slice(0, prefixStr.length);
            if (startStr === prefixStr) {
                hitPrefix = prefixStr;
                return true;
            }
            return false;
        });
        if (hitPrefix !== null) {
            return {
                prefix: hitPrefix,
                value: str.slice(hitPrefix.length),
            };
        }
        return null;
    })
        .filter((entity) => !!entity && !!entity.value);
};
export default Mentions;
