import * as React from 'react';
import omit from 'omit.js';
import classNames from 'classnames';
import Element from './Element';
import { ConfigConsumer } from '../config-provider';
const SkeletonButton = (props) => {
    const renderSkeletonButton = ({ getPrefixCls }) => {
        const { prefixCls: customizePrefixCls, className, active } = props;
        const prefixCls = getPrefixCls('skeleton', customizePrefixCls);
        const otherProps = omit(props, ['prefixCls']);
        const cls = classNames(prefixCls, `${prefixCls}-element`, {
            [`${prefixCls}-active`]: active,
        }, className);
        return (<div className={cls}>
        <Element prefixCls={`${prefixCls}-button`} {...otherProps}/>
      </div>);
    };
    return <ConfigConsumer>{renderSkeletonButton}</ConfigConsumer>;
};
SkeletonButton.defaultProps = {
    size: 'default',
};
export default SkeletonButton;
