/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import ResponsiveObserve, { responsiveArray, } from '../_util/responsiveObserve';
import devWarning from '../_util/devWarning';
import { ConfigContext } from '../config-provider';
import Row from './Row';
import DescriptionsItem from './Item';
import { cloneElement } from '../_util/reactNode';
const DEFAULT_COLUMN_MAP = {
    xxl: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 1,
};
function getColumn(column, screens) {
    if (typeof column === 'number') {
        return column;
    }
    if (typeof column === 'object') {
        for (let i = 0; i < responsiveArray.length; i++) {
            const breakpoint = responsiveArray[i];
            if (screens[breakpoint] && column[breakpoint] !== undefined) {
                return column[breakpoint] || DEFAULT_COLUMN_MAP[breakpoint];
            }
        }
    }
    return 3;
}
function getFilledItem(node, span, rowRestCol) {
    let clone = node;
    if (span === undefined || span > rowRestCol) {
        clone = cloneElement(node, {
            span: rowRestCol,
        });
        if (process.env.NODE_ENV !== 'production')
            devWarning(span === undefined, 'Descriptions', 'Sum of column `span` in a line not match `column` of Descriptions.');
    }
    return clone;
}
function getRows(children, column) {
    const childNodes = toArray(children).filter(n => n);
    const rows = [];
    let tmpRow = [];
    let rowRestCol = column;
    childNodes.forEach((node, index) => {
        var _a;
        const span = (_a = node.props) === null || _a === void 0 ? void 0 : _a.span;
        const mergedSpan = span || 1;
        // Additional handle last one
        if (index === childNodes.length - 1) {
            tmpRow.push(getFilledItem(node, span, rowRestCol));
            rows.push(tmpRow);
            return;
        }
        if (mergedSpan < rowRestCol) {
            rowRestCol -= mergedSpan;
            tmpRow.push(node);
        }
        else {
            tmpRow.push(getFilledItem(node, mergedSpan, rowRestCol));
            rows.push(tmpRow);
            rowRestCol = column;
            tmpRow = [];
        }
    });
    return rows;
}
function Descriptions({ prefixCls: customizePrefixCls, title, extra, column = DEFAULT_COLUMN_MAP, colon = true, bordered, layout, children, className, style, size, }) {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('descriptions', customizePrefixCls);
    const [screens, setScreens] = React.useState({});
    const mergedColumn = getColumn(column, screens);
    // Responsive
    React.useEffect(() => {
        const token = ResponsiveObserve.subscribe(newScreens => {
            if (typeof column !== 'object') {
                return;
            }
            setScreens(newScreens);
        });
        return () => {
            ResponsiveObserve.unsubscribe(token);
        };
    }, []);
    // Children
    const rows = getRows(children, mergedColumn);
    return (<div className={classNames(prefixCls, {
        [`${prefixCls}-${size}`]: size && size !== 'default',
        [`${prefixCls}-bordered`]: !!bordered,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className)} style={style}>
      {(title || extra) && (<div className={`${prefixCls}-header`}>
          {title && <div className={`${prefixCls}-title`}>{title}</div>}
          {extra && <div className={`${prefixCls}-extra`}>{extra}</div>}
        </div>)}

      <div className={`${prefixCls}-view`}>
        <table>
          <tbody>
            {rows.map((row, index) => (<Row key={index} index={index} colon={colon} prefixCls={prefixCls} vertical={layout === 'vertical'} bordered={bordered} row={row}/>))}
          </tbody>
        </table>
      </div>
    </div>);
}
Descriptions.Item = DescriptionsItem;
export default Descriptions;
